import * as React from "react";

import { Skeleton } from "@mui/material";

export const Loadable =
  (Component: React.LazyExoticComponent<React.FC<any>>) => (props: any) => {
    return (
      <React.Suspense
        fallback={
          <Skeleton animation="wave" height={60} variant="rectangular" />
        }
      >
        <Component {...props} />
      </React.Suspense>
    );
  };
