export enum ActionTypes {
  STOPWATCH_SHOW = "STOPWATCH_SHOW",
  STOPWATCH_REGRESSIVE = "STOPWATCH_REGRESSIVE",
  STOPWATCH_DEFINE_TIME = "STOPWATCH_DEFINE_TIME",
  STOPWATCH_SET_TIME = "STOPWATCH_SET_TIME",
  STOPWATCH_START_AND_STOP = "STOPWATCH_START_AND_STOP",
  STOPWATCH_RESET = "STOPWATCH_RESET",
}

export interface StopwatchShowType {
  type: ActionTypes.STOPWATCH_SHOW;
}

export interface StopwatchRegressiveType {
  type: ActionTypes.STOPWATCH_REGRESSIVE;
  payload: boolean;
}

export interface StopwatchDefineTimeType {
  type: ActionTypes.STOPWATCH_DEFINE_TIME;
  payload: number;
}

export interface StopwatchSetTimeType {
  type: ActionTypes.STOPWATCH_SET_TIME;
  payload: number;
}

export interface StopwatchStartAndStopType {
  type: ActionTypes.STOPWATCH_START_AND_STOP;
}

export interface StopwatchResetType {
  type: ActionTypes.STOPWATCH_RESET;
}

export type StopwatchActions =
  | StopwatchShowType
  | StopwatchRegressiveType
  | StopwatchDefineTimeType
  | StopwatchStartAndStopType
  | StopwatchResetType
  | StopwatchSetTimeType;

export function stopwatchToogleShow(): StopwatchShowType {
  return {
    type: ActionTypes.STOPWATCH_SHOW,
  };
}

export function stopwatchRegressive(payload: boolean): StopwatchRegressiveType {
  return {
    type: ActionTypes.STOPWATCH_REGRESSIVE,
    payload,
  };
}

export function stopwatchSetTime(payload: number): StopwatchSetTimeType {
  return {
    type: ActionTypes.STOPWATCH_SET_TIME,
    payload,
  };
}

export function stopwatchDefineTime(payload: number): StopwatchDefineTimeType {
  return {
    type: ActionTypes.STOPWATCH_DEFINE_TIME,
    payload,
  };
}

export function stopwatchStartAndStop(): StopwatchStartAndStopType {
  return {
    type: ActionTypes.STOPWATCH_START_AND_STOP,
  };
}

export function stopwatchReset(): StopwatchResetType {
  return {
    type: ActionTypes.STOPWATCH_RESET,
  };
}
