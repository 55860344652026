import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "layouts/DashboardLayout";

import { Loadable } from "components/elements/Loadable";
import AuthGuard from "components/modules/Guard/AuthGuards";

const SignIn = Loadable(lazy(() => import("pages/SignIn")));
const ForgotPasswordPage = Loadable(lazy(() => import("pages/ForgotPassword")));
const Dashboard = Loadable(lazy(() => import("pages/Dashboard")));
const DashboardPerformance = Loadable(
  lazy(() => import("pages/DashboardPerformance"))
);
const DashboardContent = Loadable(lazy(() => import("pages/DashboardContent")));
const DashboardQuestions = Loadable(
  lazy(() => import("pages/DashboardQuestions"))
);
const Goals = Loadable(lazy(() => import("pages/Goals")));
const GoalsDaily = Loadable(lazy(() => import("pages/GoalsDaily")));
const GoalsWeekly = Loadable(lazy(() => import("pages/GoalsWeekly")));
const GoalsAddStudy = Loadable(lazy(() => import("pages/GoalsAddStudy")));
const NoticePage = Loadable(lazy(() => import("pages/Notice")));
const StudyPlanFinalizeReopenGoals = Loadable(
  lazy(() => import("pages/StudyPlanFinalizeReopenGoals"))
);
const StudyPlanRescheduleGoals = Loadable(
  lazy(() => import("pages/StudyPlanRescheduleGoals"))
);
const StudyPlanReconfigureGoals = Loadable(
  lazy(() => import("pages/StudyPlanReconfigureGoals"))
);
const StudyPlanReconfigureGoalsEdit = Loadable(
  lazy(() => import("pages/StudyPlanReconfigureGoalsEdit"))
);
const StudyPlanEditHours = Loadable(
  lazy(() => import("pages/StudyPlanEditHours"))
);
const StudyPlanEditCycle = Loadable(
  lazy(() => import("pages/StudyPlanEditCycle"))
);
const StudyPlanPause = Loadable(lazy(() => import("pages/StudyPlanPause")));
const StudyPlanRestart = Loadable(lazy(() => import("pages/StudyPlanRestart")));
const Error = Loadable(lazy(() => import("pages/404")));

const NookMaterial = Loadable(lazy(() => import("pages/NookMaterial")));
const NookVideos = Loadable(lazy(() => import("pages/NookVideos")));
const NookEssay = Loadable(lazy(() => import("pages/NookEssay")));
const NookSimulators = Loadable(lazy(() => import("pages/NookSimulators")));
const NookQuestions = Loadable(lazy(() => import("pages/NookQuestions")));

const routes = () => [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/recuperar-senha",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/app",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/app/painel" />,
      },
      {
        path: "painel",
        element: <Dashboard />,
        children: [
          {
            path: "desempenho",
            element: <DashboardPerformance />,
          },
          {
            path: "conteudo",
            element: <DashboardContent />,
          },
          {
            path: "questoes",
            element: <DashboardQuestions />,
          },
        ],
      },
      {
        path: "metas",
        children: [
          {
            element: <Goals />,
            children: [
              {
                path: "diaria",
                element: <GoalsDaily />,
              },
              {
                path: "semanal",
                element: <GoalsWeekly />,
              },
              {
                path: "registrar-estudo",
                element: <GoalsAddStudy />,
              },
            ],
          },
        ],
      },
      {
        path: "plano-de-estudo",
        children: [
          {
            element: <NoticePage />,
            children: [
              {
                path: "finalizar-reabrir-metas",
                element: <StudyPlanFinalizeReopenGoals />,
              },
              {
                path: "reprogramar-metas",
                element: <StudyPlanRescheduleGoals />,
              },
              {
                path: "reordenar-conteudo",
                children: [
                  {
                    index: true,
                    element: <StudyPlanReconfigureGoals />,
                  },
                  {
                    path: "editar/:id",
                    element: <StudyPlanReconfigureGoalsEdit />,
                  },
                ],
              },
              {
                path: "editar-horario",
                element: <StudyPlanEditHours />,
              },
              {
                path: "editar-ciclo",
                element: <StudyPlanEditCycle />,
              },
              {
                path: "pausar-plano",
                element: <StudyPlanPause />,
              },
              {
                path: "reiniciar-plano",
                element: <StudyPlanRestart />,
              },
            ],
          },
        ],
      },
      {
        path: "escaninho",
        children: [
          {
            path: "material",
            element: <NookMaterial />,
          },
          {
            path: "videos",
            element: <NookVideos />,
          },
          {
            path: "redacao",
            element: <NookEssay />,
          },
          {
            path: "simulados",
            element: <NookSimulators />,
          },
          {
            path: "questoes",
            element: <NookQuestions />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

const AppRoutes = () => {
  const routeConfig = routes();

  return useRoutes(routeConfig);
};

export default AppRoutes;
