import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  PropsWithChildren,
} from "react";

import { COLLECTION } from "constants/collections";
import { StopwatchActions } from "store/stopwatch/actions";
import reducer, { initialState } from "store/stopwatch/reducer";

export type ContextDataType = {
  stopwatch: typeof initialState;
  dispatch: React.Dispatch<StopwatchActions>;
};

export const StopwatchContext = createContext({} as ContextDataType);

const StopwatchProvider = ({ children }: PropsWithChildren) => {
  const hours = localStorage.getItem(COLLECTION.STOPWATCH_TIME);
  const goalTime = localStorage.getItem(COLLECTION.STOPWATCH_GOAL_TIME);
  const regressive = localStorage.getItem(COLLECTION.STOPWATCH_REGRESSIVE);

  const [state, action] = useReducer(reducer, initialState, (prevState) => ({
    ...prevState,
    time: Number(hours) || 0,
    taskTime: Number(goalTime) || 0,
    regressive: Boolean(regressive) || false,
  }));

  const [stopwatch, dispatch] = useMemo(() => [state, action], [state, action]);

  return (
    <StopwatchContext.Provider value={{ stopwatch, dispatch }}>
      {children}
    </StopwatchContext.Provider>
  );
};

export const useStopwatch = () => {
  const context = useContext(StopwatchContext);

  if (!context) {
    throw new Error("useStopwatch must be used within an StopwatchProvider.");
  }

  return context;
};

export default StopwatchProvider;
