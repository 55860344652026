import { Stack, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LogoutIcon from "@mui/icons-material/Logout";

import { setSession } from "utils/token";
import AuthRepository from "repositories/auth";
import { authLogoutAction, authInitializeAction } from "store/auth/actions";

import { UserType } from "types/AuthType";
import useSettings from "hooks/useSettings";
import { useAuth } from "contexts/AuthContext";

import DrawerPlan from "./drawers/DrawerPlan";

import { themeSettingsTypes } from "theme";

const repository = new AuthRepository();

export default function ActionsIcons() {
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const { settings, saveSettings } = useSettings();

  const handleChangeTheme = (value: "light" | "dark") => {
    saveSettings({ ...settings, theme: value } as themeSettingsTypes);
  };

  async function signOut() {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken) {
        await repository.logout();
      }

      setSession(null);
      dispatch(authLogoutAction());
      dispatch(
        authInitializeAction({
          isAuthenticated: false,
          isInitialized: false,
          user: {} as UserType,
        })
      );

      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: "auto" }}
    >
      <DrawerPlan />

      <Tooltip
        title={`Alterar para o tema ${
          settings.theme === "light" ? "dark" : "light"
        }`}
      >
        <IconButton
          onClick={() =>
            handleChangeTheme(settings.theme === "light" ? "dark" : "light")
          }
        >
          {settings.theme === "light" ? (
            <DarkModeIcon
              sx={{ color: (theme) => theme.palette.common.white }}
            />
          ) : (
            <LightModeIcon
              sx={{ color: (theme) => theme.palette.common.white }}
            />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip title="Sair">
        <IconButton onClick={() => signOut()}>
          <LogoutIcon sx={{ color: (theme) => theme.palette.common.white }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
