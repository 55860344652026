import * as React from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { setSession } from "utils/token";

import StopwatchProvider from "contexts/StopwatchContext";
import DashboardProvider from "contexts/DashboardContext";

import httpAxios from "repositories";
import AuthRepository from "repositories/auth";
import { authLogoutAction } from "store/auth/actions";

import { useAuth } from "contexts/AuthContext";
import { isAxiosError } from "axios";

interface AuthGuardType {
  children: React.ReactNode;
}

const repository = new AuthRepository();

function AuthGuard({ children }: AuthGuardType) {
  const navigate = useNavigate();
  const { auth, dispatch } = useAuth();

  const signOut = React.useCallback(async () => {
    try {
      setSession(null);
      dispatch(authLogoutAction());

      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken) {
        await repository.logout();
      }
      navigate("/sign-in", {
        replace: true,
      });
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, navigate]);

  React.useLayoutEffect(() => {
    const interceptorId = httpAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosError(error)) {
          const status = error.response?.status;
          const code = error.response?.data.code;

          if (status === 401 && code === "UNAUTHORIZED") {
            signOut();
          }
        }

        return Promise.reject(error);
      }
    );

    // Clean up the side effect when the component unmounts
    return () => {
      httpAxios.interceptors.response.eject(interceptorId);
    };
  }, [navigate, signOut]);

  if (auth.isInitialized && !auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (auth.isAuthenticated) {
    return (
      <StopwatchProvider>
        <DashboardProvider>{children}</DashboardProvider>
      </StopwatchProvider>
    );
  }

  return null;
}

export default AuthGuard;
