import { z } from "zod";

export const envSchema = z.object({
  NODE_ENV: z.enum(["production", "development", "test"]),
  REACT_APP_URL_APP: z.string().url(),
  REACT_APP_API_URL: z.string().url(),
  REACT_APP_URL_SPACE: z.string().url(),
  REACT_APP_URL_ACCOUNT: z.string().url(),
});

export const env = envSchema.parse(process.env);
