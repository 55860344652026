import { produce } from "immer";

import { ActionTypes, StopwatchActions } from "store/stopwatch/actions";

export const initialState = {
  show: false,
  time: 0,
  taskTime: 0,
  isRunning: false,
  regressive: false,
};

export default function reducer(
  state: typeof initialState,
  action: StopwatchActions
) {
  switch (action.type) {
    case ActionTypes.STOPWATCH_SHOW:
      return produce(state, (draft) => {
        draft.show = !state.show;
      });

    case ActionTypes.STOPWATCH_REGRESSIVE:
      return produce(state, (draft) => {
        draft.regressive = action.payload;
      });

    case ActionTypes.STOPWATCH_DEFINE_TIME:
      return produce(state, (draft) => {
        draft.taskTime = action.payload;
        draft.time = action.payload;
      });

    case ActionTypes.STOPWATCH_SET_TIME:
      return produce(state, (draft) => {
        draft.time = state.regressive ? action.payload - 1 : action.payload + 1;
      });

    case ActionTypes.STOPWATCH_START_AND_STOP:
      return produce(state, (draft) => {
        draft.isRunning = !state.isRunning;
      });

    case ActionTypes.STOPWATCH_RESET:
      return produce(state, (draft) => {
        draft.time = state.regressive ? state.taskTime : 0;
      });

    default:
      return state;
  }
}
