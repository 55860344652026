import { useQuery } from "@tanstack/react-query";

import DashboardRepository from "repositories/dashboard";
import type { GuideDashboardType } from "types/DashboardType";

const repository = new DashboardRepository();

export const useDashboardContentQuery = ({
  onSuccess,
}: {
  onSuccess?: (data: GuideDashboardType) => void;
}) => {
  return useQuery<{ data: GuideDashboardType }>({
    queryKey: ["get-dashboard-content"],
    queryFn: async () => await repository.getContentDashboard(),
    structuralSharing(_, newData) {
      onSuccess?.(newData?.data);
    },
  });
};
