import axios from "axios";
import { env } from "env";

const httpAxios = axios.create({
  baseURL: env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

httpAxios.interceptors.request.use(function (config) {
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }

  return config;
});

export default httpAxios;
