import { Fragment, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Link,
} from "@mui/material";

import {
  Clear,
  ReceiptLong,
  EventAvailable,
  Textsms,
  Link as LinkIcon,
  Badge,
  WhatsApp,
} from "@mui/icons-material";

import PlanRepository from "repositories/plan";

import { H3 } from "components/elements/Typography";
import LayoutDrawer from "./LayoutDrawer";
import { PlanType } from "types/PlanType";
import { format, isValid } from "date-fns";

const repository = new PlanRepository();

const DrawerPlan = () => {
  const [open, setOpen] = useState(false);

  const { isLoading, data } = useQuery<{ data: PlanType }>({
    queryKey: ["plan"],
    queryFn: async () => await repository.get(),
    enabled: open,
  });

  return (
    <Fragment>
      <Tooltip title="Informações do Plano">
        <IconButton onClick={() => setOpen(true)}>
          <ReceiptLong sx={{ color: (theme) => theme.palette.common.white }} />
        </IconButton>
      </Tooltip>

      <LayoutDrawer
        open={open}
        onClose={() => setOpen(false)}
        drawerWidth={540}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <H3>Informações do Plano</H3>

          <IconButton onClick={() => setOpen(false)}>
            <Clear sx={{ color: (theme) => theme.palette.common.white }} />
          </IconButton>
        </Stack>

        <Box>
          {isLoading ? <LinearProgress /> : null}

          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Badge />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Nome do Plano"
                secondary={data?.data?.title}
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <EventAvailable />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Data da Prova"
                secondary={
                  data?.data?.date_exam &&
                  isValid(new Date(data?.data?.date_exam)) &&
                  format(new Date(data?.data?.date_exam), "dd/MM/yyyy")
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Textsms />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Descrição"
                secondary={data?.data?.description}
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LinkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Link do Edital"
                secondary={
                  <Link
                    href={data?.data?.link_edital}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Clique aqui
                  </Link>
                }
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WhatsApp />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Link de Grupo do WhatsApp"
                secondary={
                  <Link
                    href={data?.data?.link_whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Clique aqui
                  </Link>
                }
              />
            </ListItem>
          </List>
        </Box>
      </LayoutDrawer>
    </Fragment>
  );
};

export default DrawerPlan;
