import { produce } from "immer";
import type { UserType } from "types/AuthType";

import { ActionTypes, AuthActions } from "store/auth/actions";

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isInitialized: false,
  user: {} as UserType,
};

export default function reducer(
  state: typeof initialState,
  action: AuthActions
) {
  switch (action.type) {
    case ActionTypes.AUTH_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });

    case ActionTypes.AUTH_INITIALIZE:
      return produce(state, (draft) => {
        const { isAuthenticated, isInitialized, user } = action.payload;

        if (isAuthenticated) draft.isAuthenticated = isAuthenticated;
        if (isInitialized) draft.isInitialized = isInitialized;
        if (user) draft.user = user;
      });

    case ActionTypes.AUTH_USER:
      return produce(state, (draft) => {
        draft.user = action.payload;
      });

    case ActionTypes.AUTH_LOGOUT:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.isAuthenticated = false;
        draft.user = {} as UserType;
      });

    default:
      return state;
  }
}
