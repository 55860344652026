import httpAxios from "repositories";

import {
  CredentialsProps,
  SessionType,
  UserType,
  PurchasesType,
} from "types/AuthType";

export default class AuthRepository {
  async session({
    email,
    password,
    device_name = "web",
  }: CredentialsProps): Promise<SessionType> {
    await httpAxios.get("sanctum/csrf-cookie");

    const { data } = await httpAxios.post<SessionType>("/token", {
      email,
      password,
      device_name,
    });

    return data;
  }

  async forgotPassword(
    payload: Omit<CredentialsProps, "password" | "device_name">
  ) {
    const { data } = await httpAxios.post("/forget-password", payload);

    return data;
  }

  async logout() {
    const { data } = await httpAxios.post("/revoke-token");

    return data;
  }

  async user(): Promise<{ data: UserType }> {
    const { data } = await httpAxios.get<{ data: UserType }>("api/user");

    return data;
  }

  async userUpdate(user: UserType): Promise<{ data: UserType }> {
    const { data } = await httpAxios.put<{ data: UserType }>(
      "api/customer",
      user
    );

    return data;
  }

  async purchases(): Promise<{ data: PurchasesType }> {
    const { data } = await httpAxios.get<{ data: PurchasesType }>(
      "api/eduzz/purchases"
    );

    return data;
  }
}
