import { produce } from "immer";
import { format } from "date-fns";

import type { ChartType } from "types/DashboardType";

import { ActionTypes, DashboardActions } from "store/dashboard/actions";

export const initialState = {
  loading: false,
  data: {
    lastGoal: format(new Date(), "yyyy-MM-dd"),
    goalWeek: 0,
    daysForExam: 0,
    goals: {
      progress: 0,
      total_goals_done: 0,
      total_goals: 0,
    },
    accessDays: {
      remaining: 0,
      total: 0,
    },
    hoursAndQuestions: {
      hours_total: 0,
      questions: 0,
    },
    content: [] as ChartType[],
    questions: [] as ChartType[],
    performance: [] as ChartType[],
  },
};

export default function reducer(
  state: typeof initialState,
  action: DashboardActions
) {
  switch (action.type) {
    case ActionTypes.DASHBOARD_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });

    case ActionTypes.DASHBOARD_FETCH_All:
      return produce(state, (draft) => {
        draft.data = action.payload;
      });

    default:
      return state;
  }
}
