import DashboardIcon from "@mui/icons-material/Dashboard";
import PieChartIcon from "@mui/icons-material/PieChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdsClickIcon from "@mui/icons-material/AdsClick";

import InventoryIcon from "@mui/icons-material/Inventory";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import HelpIcon from "@mui/icons-material/Help";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

const prefix = "/app";

export const navigations = [
  {
    name: "Painel",
    path: `${prefix}/painel`,
    icon: DashboardIcon,
  },
  {
    name: "Desempenho",
    path: `${prefix}/painel/desempenho`,
    icon: QueryStatsIcon,
  },
  {
    name: "Conteúdo",
    path: `${prefix}/painel/conteudo`,
    icon: PieChartIcon,
  },
  {
    name: "Questões",
    path: `${prefix}/painel/questoes`,
    icon: BarChartIcon,
  },
  {
    name: "Metas",
    icon: AdsClickIcon,
    children: [
      {
        name: "Diária",
        path: `${prefix}/metas/diaria`,
      },
      {
        name: "Semanal",
        path: `${prefix}/metas/semanal`,
      },
      {
        name: "Regisrar Estudo",
        path: `${prefix}/metas/registrar-estudo`,
      },
    ],
  },
  {
    name: "Plano de Estudo",
    icon: ManageAccountsIcon,
    children: [
      {
        name: "Finalizar/Reabrir Metas",
        path: `${prefix}/plano-de-estudo/finalizar-reabrir-metas`,
      },
      {
        name: "Reprogramar Metas",
        path: `${prefix}/plano-de-estudo/reprogramar-metas`,
      },
      {
        name: "Reordenar Conteúdo",
        path: `${prefix}/plano-de-estudo/reordenar-conteudo`,
      },
      {
        name: "Editar Horário",
        path: `${prefix}/plano-de-estudo/editar-horario`,
      },
      {
        name: "Editar Ciclos",
        path: `${prefix}/plano-de-estudo/editar-ciclo`,
      },
      {
        name: "Pausar Plano",
        path: `${prefix}/plano-de-estudo/pausar-plano`,
      },
      {
        name: "Reiniciar Plano",
        path: `${prefix}/plano-de-estudo/reiniciar-plano`,
      },
    ],
  },
  {
    name: "Escaninho (em breve)",
    icon: InventoryIcon,
    children: [
      {
        name: "Material",
        icon: InsertDriveFileIcon,
        path: `${prefix}/escaninho/material`,
      },
      {
        name: "Vídeos",
        icon: PlayCircleIcon,
        path: `${prefix}/escaninho/videos`,
      },
      {
        name: "Redação",
        icon: EditNoteIcon,
        path: `${prefix}/escaninho/redacao`,
      },
      {
        name: "Simulados",
        icon: SpeakerNotesIcon,
        path: `${prefix}/escaninho/simulados`,
      },
      {
        name: "Questões",
        icon: HelpIcon,
        path: `${prefix}/escaninho/questoes`,
      },
    ],
  },
];
