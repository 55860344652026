import { PropsWithChildren } from "react";

import { ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";

import AuthProvider from "contexts/AuthContext";

import useSettings from "hooks/useSettings";
import { createCustomTheme } from "theme";

const queryClient = new QueryClient();

export const AppProvider = ({ children }: PropsWithChildren) => {
  const { settings } = useSettings();

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <AuthProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            {children}

            <Toaster position="top-right" />
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthProvider>
  );
};
