import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import AuthRepository from "repositories/auth";
import UseQuery from "hooks/useQuery";
import { setSession } from "utils/token";

import {
  AuthActions,
  authLoandingAction,
  authInitializeAction,
  authLogoutAction,
} from "store/auth/actions";
import reducer, { initialState } from "store/auth/reducer";

export type ContextDataType = {
  auth: typeof initialState;
  dispatch: React.Dispatch<AuthActions>;
};

export const AuthContext = createContext({} as ContextDataType);

const repository = new AuthRepository();

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, action] = useReducer(reducer, initialState);

  const query = UseQuery();

  const [auth, dispatch] = useMemo(() => [state, action], [state, action]);

  useEffect(() => {
    async function initialize() {
      try {
        dispatch(authLoandingAction(true));
        dispatch(
          authInitializeAction({
            isInitialized: true,
            isAuthenticated: true,
          })
        );

        const accessToken = window.localStorage.getItem("accessToken");

        if (!accessToken) {
          throw new Error("error");
        }

        setSession(accessToken);

        const { data } = await repository.user();

        dispatch(
          authInitializeAction({
            isAuthenticated: true,
            isInitialized: true,
            user: data,
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(authLogoutAction());
      } finally {
        dispatch(authLoandingAction(false));
      }
    }

    initialize();
  }, [dispatch, query]);

  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
};

export default AuthProvider;
