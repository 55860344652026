import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import {
  DashboardActions,
  dashboardFetchAllAction,
  dashboardLoandingAction,
} from "store/dashboard/actions";
import reducer, { initialState } from "store/dashboard/reducer";

import { useDashboardContentQuery } from "hooks/useDashboardQuery";

export type ContextDataType = {
  dashboard: typeof initialState;
  dispatch: React.Dispatch<DashboardActions>;
};

export const DashboardContext = createContext({} as ContextDataType);

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [state, action] = useReducer(reducer, initialState);

  const [dashboard, dispatch] = useMemo(() => [state, action], [state, action]);

  const { isLoading } = useDashboardContentQuery({
    onSuccess: (data) => {
      dispatch(dashboardFetchAllAction(data));
    },
  });

  useEffect(() => {
    dispatch(dashboardLoandingAction(isLoading));
  }, [dispatch, isLoading]);

  return (
    <DashboardContext.Provider value={{ dashboard, dispatch }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error("useDashboard must be used within an DashboardProvider.");
  }

  return context;
};

export default DashboardProvider;
