import type { UserType } from "types/AuthType";

export enum ActionTypes {
  AUTH_LOADING = "AUTH_LOADING",
  AUTH_INITIALIZE = "AUTH_INITIALIZE",
  AUTH_USER = "AUTH_USER",
  AUTH_LOGOUT = "AUTH_LOGOUT",
}

export interface AuthLoadingType {
  type: ActionTypes.AUTH_LOADING;
  payload: boolean;
}

export interface AuthInitializeType {
  type: ActionTypes.AUTH_INITIALIZE;
  payload: {
    isAuthenticated?: boolean;
    isInitialized?: boolean;
    user?: UserType;
  };
}

export interface AuthUserType {
  type: ActionTypes.AUTH_USER;
  payload: UserType;
}

export interface AuthLogoutType {
  type: ActionTypes.AUTH_LOGOUT;
}

export type AuthActions =
  | AuthLoadingType
  | AuthInitializeType
  | AuthUserType
  | AuthLogoutType;

export function authLoandingAction(payload: boolean): AuthLoadingType {
  return {
    type: ActionTypes.AUTH_LOADING,
    payload,
  };
}

export function authInitializeAction(payload: {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user?: UserType;
}): AuthInitializeType {
  return {
    type: ActionTypes.AUTH_INITIALIZE,
    payload,
  };
}

export function authUserAction(payload: UserType): AuthUserType {
  return {
    type: ActionTypes.AUTH_USER,
    payload,
  };
}

export function authLogoutAction(): AuthLogoutType {
  return {
    type: ActionTypes.AUTH_LOGOUT,
  };
}
