import httpAxios from "./index";
import { GuideDashboardType } from "../types/DashboardType";

export default class DashboardRepository {
  async getContentDashboard(): Promise<{ data: GuideDashboardType }> {
    const { data } = await httpAxios.get<{ data: GuideDashboardType }>(
      "/api/guide/charts/dashboard"
    );
    return data;
  }
}
