import "./i18n";

import { CssBaseline } from "@mui/material";

import { AppProvider } from "contexts";

import AppRoutes from "routes";

const App = () => {
  return (
    <AppProvider>
      <AppRoutes />

      <CssBaseline />
    </AppProvider>
  );
};

export default App;
