import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, ButtonBase, styled } from "@mui/material";

import { navigations } from "layouts/layout-parts/navigation";

import { Paragraph, Span } from "components/elements/Typography";

import SidebarAccordion from "./SidebarAccordion";

type Active = { active: number; collapsed?: boolean };
type Compact = { compact: number };
type ActiveCompact = Active & Compact;

const NavItemButton = styled(ButtonBase)<Active>(
  ({ theme, active, collapsed }) => ({
    height: 44,
    width: "100%",
    borderRadius: 8,
    marginBottom: 4,
    padding: "0 18px",
    justifyContent: "flex-start",
    transition: "all 0.15s ease",
    ...(!collapsed && {
      backgroundColor: active ? theme.palette.secondary.main : "transparent",
    }),
    ...(collapsed && {
      color: active ? theme.palette.secondary.main : theme.palette.common.white,
    }),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      ...(collapsed && {
        backgroundColor: "transparent",
        color: theme.palette.secondary.main,
      }),
    },
  })
);

const ListLabel = styled(Paragraph)<Compact>(({ theme, compact }) => ({
  fontWeight: 700,
  fontSize: "12px",
  marginTop: "20px",
  marginLeft: "15px",
  marginBottom: "10px",
  textTransform: "uppercase",
  transition: "all 0.15s ease",
  color: theme.palette.secondary.main,
  ...(compact && { opacity: 0, width: 0 }),
}));

const ExternalLink = styled("a")(({ theme }) => ({
  overflow: "hidden",
  whiteSpace: "pre",
  marginBottom: "8px",
  textDecoration: "none",
  color: theme.palette.text.primary,
}));

const StyledText = styled(Span)<ActiveCompact>(
  ({ theme, compact, active, collapsed }) => ({
    whiteSpace: "nowrap",
    paddingLeft: "0.8rem",
    transition: "all 0.15s ease",
    fontSize: "13px",
    fontWeight: 500,
    ...(compact && { opacity: 0, width: 0 }),
    ...(!collapsed && {
      color: theme.palette.common.white,
    }),
    ...(collapsed && {
      color: active ? theme.palette.secondary.main : theme.palette.common.white,
    }),
    "&:hover": {
      ...(collapsed && {
        color: theme.palette.secondary.main,
      }),
    },
  })
);

const BulletIcon = styled("div")<Active>(({ theme, active, collapsed }) => ({
  width: active ? 8 : 6,
  height: active ? 8 : 6,
  marginLeft: "10px",
  marginRight: "8px",
  overflow: "hidden",
  borderRadius: "50%",
  background: active
    ? theme.palette.secondary.main
    : theme.palette.common.white,
  boxShadow: "none",
  "&:hover": {
    ...(collapsed && {
      background: theme.palette.secondary.main,
    }),
  },
}));

const BadgeValue = styled(Box)<Compact>(({ compact, theme }) => ({
  color: "white",
  fontSize: "12px",
  fontWeight: 500,
  padding: "1px 6px",
  overflow: "hidden",
  borderRadius: "300px",
  backgroundColor: theme.palette.primary.main,
  display: compact ? "none" : "unset",
}));

// Common icon style
const iconStyle = (active: any) => ({
  fontSize: 18,
  marginRight: "4px",
  color: "common.white",
});

// ---------------------------------------------------------------
type MultiLevelMenuProps = {
  sidebarCompact: boolean;
  setSidebarCompact?: () => void | undefined;
};
// ---------------------------------------------------------------

const MultiLevelMenu: FC<MultiLevelMenuProps> = ({
  sidebarCompact,
  setSidebarCompact,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // handle active current page
  const activeRoute = (path: string) => (pathname === path ? 1 : 0);
  // handle navigate to another route or page
  const handleNavigation = (path: string) => {
    navigate(path);
    setSidebarCompact?.();
  };

  // ACTIVATE SIDEBAR COMPACT
  const COMPACT = sidebarCompact ? 1 : 0;

  //   RECURSIVE FUNCTION TO RENDER MULTI LEVEL MENU
  const renderLevels = (data: any, collapsed: boolean) => {
    return data.map((item: any, index: any) => {
      if (item.type === "label")
        return (
          <ListLabel key={index} compact={COMPACT}>
            {item.label}
          </ListLabel>
        );

      if (item.children) {
        return (
          <SidebarAccordion key={index} item={item} sidebarCompact={COMPACT}>
            {renderLevels(item.children, true)}
          </SidebarAccordion>
        );
      } else if (item.type === "extLink") {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            rel="noopener noreferrer"
            target="_blank"
          >
            <NavItemButton key={item.name} name="child" active={0}>
              {(() => {
                if (item.icon) {
                  return <item.icon sx={iconStyle(0)} />;
                } else {
                  return (
                    <span className="item-icon icon-text">{item.iconText}</span>
                  );
                }
              })()}

              <StyledText compact={COMPACT} active={activeRoute(item.path)}>
                {item.name}
              </StyledText>

              <Box mx="auto" />

              {item.badge && (
                <BadgeValue compact={COMPACT}>{item.badge.value}</BadgeValue>
              )}
            </NavItemButton>
          </ExternalLink>
        );
      } else {
        return (
          <Box key={index}>
            <NavItemButton
              key={item.name}
              className="navItem"
              collapsed={collapsed}
              active={activeRoute(item.path)}
              onClick={() => handleNavigation(item.path)}
            >
              {item?.icon ? (
                <item.icon sx={iconStyle(activeRoute(item.path))} />
              ) : (
                <BulletIcon active={activeRoute(item.path)} />
              )}

              <StyledText
                compact={COMPACT}
                collapsed={collapsed}
                active={activeRoute(item.path)}
              >
                {item.name}
              </StyledText>

              <Box mx="auto" />

              {item.badge && (
                <BadgeValue compact={COMPACT}>{item.badge.value}</BadgeValue>
              )}
            </NavItemButton>
          </Box>
        );
      }
    });
  };

  return <>{renderLevels(navigations, false)}</>;
};

export default MultiLevelMenu;
