import httpAxios from "repositories";
import { PlanType } from "types/PlanType";

export default class PlanRepository {
  async get() {
    const { data } = await httpAxios.get<{ data: PlanType }>(
      "/api/guide/plano"
    );

    return data;
  }
}
