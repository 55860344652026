import type { GuideDashboardType } from "types/DashboardType";

export enum ActionTypes {
  DASHBOARD_LOADING = "DASHBOARD_LOADING",
  DASHBOARD_FETCH_All = "DASHBOARD_FETCH_All",
}

export interface DashboardLoadingType {
  type: ActionTypes.DASHBOARD_LOADING;
  payload: boolean;
}

export interface DashboardFetchAllType {
  type: ActionTypes.DASHBOARD_FETCH_All;
  payload: GuideDashboardType;
}

export type DashboardActions = DashboardFetchAllType | DashboardLoadingType;

export function dashboardLoandingAction(
  payload: boolean
): DashboardLoadingType {
  return {
    type: ActionTypes.DASHBOARD_LOADING,
    payload,
  };
}

export function dashboardFetchAllAction(
  data: GuideDashboardType
): DashboardFetchAllType {
  return {
    type: ActionTypes.DASHBOARD_FETCH_All,
    payload: data,
  };
}
