import { FC, lazy } from "react";
import { useMatch } from "react-router-dom";
import {
  AppBar,
  Box,
  Card,
  Grid,
  Stack,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

import ActionsIcons from "./layout-parts/ActionsIcons";
import { Loadable } from "components/elements/Loadable";

// ------------------------------------------------
type DashboardHeaderProps = {
  setShowSideBar: () => void;
  setShowMobileSideBar: () => void;
};
// ------------------------------------------------

const WelcomeCardComponent = Loadable(
  lazy(() => import("./layout-parts/WelcomeCard"))
);
const NoticeComponent = Loadable(lazy(() => import("./layout-parts/Notice")));
const AdvertisementsComponent = Loadable(
  lazy(() => import("./layout-parts/Advertisements"))
);

// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)<{ width?: number }>(({ theme, width }) => ({
  height: 3,
  margin: "5px",
  marginLeft: 0,
  width: width || 25,
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

const DashboardHeader: FC<DashboardHeaderProps> = ({
  setShowMobileSideBar,
}) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));
  const isMatchDashboard = useMatch("/dashboard");

  return (
    <DashboardHeaderRoot position="relative">
      {downMd && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <StyledToolBar>
            <Box sx={{ cursor: "pointer" }} onClick={setShowMobileSideBar}>
              <ToggleIcon />
              <ToggleIcon width={18} />
              <ToggleIcon width={9} />
            </Box>
          </StyledToolBar>
          <ActionsIcons />
        </Stack>
      )}

      <Box hidden={downMd && !isMatchDashboard}>
        <Card
          sx={{
            padding: 2,
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.common.white,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item md={4} xs={12}>
              <WelcomeCardComponent />
            </Grid>

            <Grid item md={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={7} xs={12}>
                  <NoticeComponent />
                </Grid>
                <Grid item md={5} xs={12}>
                  <AdvertisementsComponent />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
